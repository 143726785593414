import React from 'react';
import aej from "../images/Publications/aej.jpg";
import idt from "../images/Publications/idt.jpg";
// import ieee from "../images/Logos/ieee.jpg";
import JournalCard from '../Utilities/JournalCard';

const Publications = () => {
  return (
    <div>
        <h1 className='text-4xl font-semibold text-center m-3'>Publications</h1>
        <hr className="mb-4 border-black border-2 w-96 m-auto"/>
        {/* <p className='text-lg font-medium m-3 text-center'>The proceedings of ICCRDA 2025 will be published by IEEE  Computer Society (IEEE Xplore) indexed by all reputed databases like Scopus, EI, WoS.</p> */}
        <p className='text-lg font-medium mt-8 text-center'>Extended good quality papers will published in the SCI/Scopus indexed journals.:</p>
        <div className='bg-gray-300 w-2/3 m-auto p-4 my-4 flex flex-wrap'>
           <JournalCard name="Alexandria Engineering Journal" link="https://www.sciencedirect.com/journal/alexandria-engineering-journal" journal={aej}/>    
           <JournalCard name="Intelligent Decision Technologies" link="https://www.iospress.com/catalog/journals/intelligent-decision-technologies" journal={idt}/> 
           {/* <JournalCard name="IEEE  Computer Society" link="" journal={ieee}/>       */}
        </div>
    </div>
  )
}

export default Publications